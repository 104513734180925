import { Box, Flex, system, SystemProps } from "flicket-ui";
import {
  CSSProperties,
  ElementType,
  FC,
  HTMLAttributes,
  ReactNode,
  useRef,
  useState,
} from "react";
import styled, { css, CSSProp, DefaultTheme } from "styled-components";
import { Icon, IconProps } from "../Icon/Icon";
import {
  CheckCircle,
  Copy,
  DownloadSimple,
  Envelope,
  IconContext,
  Link,
  PencilSimple,
  Prohibit,
  SignOut,
  Trash,
} from "@phosphor-icons/react";
import { useOnClickOutside } from "usehooks-ts";
import { HoverIconDropdownButton } from "~features/generalAdmissionEvent/components/common/HoverIcon/HoverIcon";
import { omit } from "@styled-system/props";
import { exhaustiveGuard } from "~lib";

const Button = styled(Flex).attrs({
  as: "button",
  py: "6/4",
  color: "N700",
  fontSize: 2,
  lineHeight: "18px" as any,
  fontWeight: "extraBold",
})<
  SystemProps & {
    onClick: () => void;
    arrowColor?: keyof DefaultTheme["colors"];
    $arrowHide?: boolean;
  }
>`
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #fff;
  height: auto;
  padding-left: ${(p) => p.theme.space[2]}px;
  padding-right: ${(p) => p.theme.space[2]}px;

  box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.07),
    0px 1.6711px 12.1155px rgba(0, 0, 0, 0.0503198),
    0px 0.893452px 6.47753px rgba(0, 0, 0, 0.0417275),
    0px 0.500862px 3.63125px rgba(0, 0, 0, 0.035),
    0px 0.266004px 1.92853px rgba(0, 0, 0, 0.0282725),
    0px 0.11069px 0.802504px rgba(0, 0, 0, 0.0196802);

  cursor: pointer;
  user-select: none;

  transition: background 0.2s, box-shadow 0.15s, border-color 0.2s, color 0.2s;

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-top: 4px solid
      ${(p) => (p.arrowColor ? p.arrowColor : p.theme.colors.P300)};

    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    display: ${(p) => (p.$arrowHide ? "none" : "block")};

    margin-left: ${(p) => p.theme.space["6/4"]}px;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &:active {
    box-shadow: none;
  }

  ${system}
`;

const Options = styled(Flex).attrs({
  as: "ul",
  flexDir: "column",
  bg: "white",
  p: 1,
  borderRadius: "sm",
})`
  position: absolute;
  top: calc(100% + 4px);
  list-style-type: none;
  white-space: nowrap;
  width: fit-content;
  min-width: 100%;
  box-shadow: 0px 9px 39px rgba(0, 0, 0, 0.08),
    0px 2.01027px 8.71116px rgba(0, 0, 0, 0.0575),
    0px 0.598509px 2.59354px rgba(0, 0, 0, 0.0325);

  transition: all 0.2s ease;
  z-index: 3;
`;

const Option = styled(Flex).attrs<{ href?: string }>((p) => ({
  as: p.href ? "a" : "button",
  py: "12px",
  pr: "12px",
  width: 1,
  bg: "white",
  borderRadius: "xs",
  fontSize: 2,
  fontWeight: "medium",
  lineHeight: 1,
}))<{ hasIcon?: boolean }>`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  transition: background 0.2s ease;

  &:hover {
    background-color: ${(p) => p.theme.colors.N200};
  }

  padding-left: 12px;
`;

type IconOrTypeProp =
  | {
      icon?: IconProps["icon"];
      type?: never;
    }
  | {
      icon?: never;
      type?:
        | "edit"
        | "delete"
        | "copy"
        | "enable"
        | "disable"
        | "link"
        | "email"
        | "log-out"
        | "download";
    };

export type DropdownOption = {
  label: string;
  labelComponent?: ReactNode;
  onClick?: () => any;
  href?: string;
} & IconOrTypeProp &
  Partial<HTMLAnchorElement | HTMLButtonElement>;

export interface DropdownButtonProps {
  isActive?: boolean;
  onClick?: () => void;
  style?: IDropdown["dropdownButtonStyle"];
  arrowColor?: IDropdown["arrowColor"];
}

interface IDropdown {
  options: DropdownOption[];
  align?: string;
  dropdownButtonStyle?: CSSProperties;
  optionsStyle?: CSSProperties;
  optionStyle?: CSSProp;
  optionWrapperStyle?: CSSProp;
  arrowColor?: keyof DefaultTheme["colors"];
  DropdownButton?: ElementType;
  arrowHide?: boolean;
  dropdownHeader?: ReactNode;
}

export const Dropdown: FC<
  IDropdown & SystemProps & HTMLAttributes<HTMLDivElement>
> = ({
  options,
  align = "right",
  children,
  dropdownButtonStyle,
  optionsStyle,
  optionStyle,
  arrowColor,
  arrowHide,
  DropdownButton,
  optionWrapperStyle,
  dropdownHeader,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLElement>(null);

  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  const DropdownButtonComponent = DropdownButton ?? Button;

  return (
    <Flex
      flexDir="column"
      position="relative"
      width="fit-content"
      {...props}
      ref={dropdownRef}
      flexShrink={0}
    >
      <DropdownButtonComponent
        style={dropdownButtonStyle}
        arrowColor={arrowColor}
        $arrowHide={arrowHide}
        isActive={isOpen}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {children}
      </DropdownButtonComponent>

      {isOpen && (
        <Options
          left={align === "left" && 0}
          right={align === "right" && 0}
          style={optionsStyle}
        >
          <DropdownHeader>{dropdownHeader}</DropdownHeader>

          {options.map(
            ({
              label,
              onClick,
              icon: maybeIcon,
              type,
              labelComponent,
              ...rest
            }) => {
              const icon = maybeIcon ?? getIconFromType(type);

              return (
                <li
                  key={label}
                  data-testid="dropdown-option"
                  css={optionWrapperStyle}
                >
                  <Option
                    onClick={(e) => {
                      e.stopPropagation();
                      onClick?.();
                      setIsOpen(false);
                    }}
                    hasIcon={!!icon}
                    css={optionStyle}
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                    {...omit(rest)}
                  >
                    {icon && (
                      <Icon
                        icon={icon}
                        mr={1}
                        color={"N800"}
                        fontSize={"18px" as any}
                      />
                    )}
                    {labelComponent ?? label}
                  </Option>
                </li>
              );
            }
          )}
        </Options>
      )}
    </Flex>
  );
};

// Helper function to map dropdown option types to icons for visual consistency
function getIconFromType(type: DropdownOption["type"]) {
  switch (type) {
    case "copy":
      return <Copy />;
    case "link":
      return <Link />;
    case "delete":
      return <Trash />;
    case "edit":
      return <PencilSimple />;
    case "enable":
      return <CheckCircle />;
    case "disable":
      return <Prohibit />;
    case "email":
      return <Envelope />;
    case "log-out":
      return <SignOut />;
    case "download":
      return <DownloadSimple />;
  }
}

export const DropdownWithIconButton = (props: IDropdown & SystemProps) => {
  return (
    <IconContext.Provider
      value={{
        size: 18,
        weight: "regular",
      }}
    >
      <Dropdown
        DropdownButton={props.DropdownButton ?? HoverIconDropdownButton}
        optionStyle={css((p) => ({
          color: p.theme.colors.N800,
          "&:hover": {
            backgroundColor: p.theme.colors.hoverBackground6,
          },
        }))}
        {...props}
      />
    </IconContext.Provider>
  );
};

function DropdownHeader({ children }: { children: ReactNode }) {
  if (!children) return null;
  return (
    <Box
      px={2}
      py={1}
      borderBottom="1px solid"
      borderColor="N200"
      whiteSpace="normal"
    >
      {children}
    </Box>
  );
}
